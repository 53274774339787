<template>
  <div>
    <v-app-bar
      app
      dark
      :clipped-left="$vuetify.breakpoint.mdAndUp"
      :color="template.colors.navigation"
      :elevation="0"
      :style="`
        padding-top: env(safe-area-inset-top);
        height: 80px + env(safe-area-inset-top);
        color: ${template.colors.navigation_text};
        z-index: 9999;
      `"
      id="navbar"
    >
      <v-app-bar-nav-icon
        :x-large="$vuetify.breakpoint.lgAndUp"
        :color="template.colors.navigation_text"
        @click="menu = true"
        v-if="!menu"
      ></v-app-bar-nav-icon>
      <v-btn
        :x-large="$vuetify.breakpoint.lgAndUp"
        icon
        :color="template.colors.navigation_text"
        v-if="menu"
        @click="menu = false"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-spacer v-if="$vuetify.breakpoint.mdAndDown"></v-spacer>
      <v-avatar
        :large="$vuetify.breakpoint.mdAndDown"
        :x-large="$vuetify.breakpoint.lgAndUp"
        tile
        class="ml-0 ml-lg-5"
        v-if="!template.var.logo_only"
      >
        <v-img contain :src="`/img/${template.var.logo}`"></v-img>
      </v-avatar>
      <v-toolbar-title class="ml-2" v-if="!template.var.logo_only">
        {{ template.var.app_name }}
      </v-toolbar-title>
      <v-img
        v-if="template.var.logo_only"
        height="100%"
        contain
        position="left center"
        :src="`/img/${template.var.logo}`"
      ></v-img>
      <v-spacer></v-spacer>
      <v-btn
        disabled
        icon
        v-if="$vuetify.breakpoint.mdAndDown || !user"
      ></v-btn>
      <v-menu
        bottom
        left
        :color="template.colors.navigation"
        offset-y
        v-if="$vuetify.breakpoint.lgAndUp && user.data"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            text
            absolute
            right
            :color="template.colors.navigation_text"
            v-bind="attrs"
            v-on="on"
          >
            {{ user.displayName }}
            <v-avatar tile>
              <v-img
                v-if="template.var.vereinslogos"
                contain
                :src="user.data.verein.logo"
              />
              <v-icon v-if="!template.var.vereinslogos">mdi-account</v-icon>
            </v-avatar>
          </v-btn>
        </template>

        <v-list>
          <v-list-item to="/mein-account">
            <v-list-item-title>
              <v-icon class="mr-2">mdi-account</v-icon>
              Mein Account
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click="logout()">
            <v-list-item-title>
              <v-icon class="mr-2">mdi-logout</v-icon>
              Logout
            </v-list-item-title>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list dense nav>
          <v-subheader>
            Hilfe
          </v-subheader>
          <v-list-item
            link
            href="https://github.com/timvallandi/flvw-checkin"
            target="_blank"
          >
            <v-list-item-icon>
              <v-icon>mdi-github</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Einen Fehler melden</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-slide-x-transition>
      <v-container
        fluid
        v-if="menu"
        :style="`position: fixed; z-index: 8888; height: 100vh; width: 100vw; left: 0; padding-top: 5vh; padding-bottom: 15vh; overflow-x: hidden; background-color: ${template.colors.navigation};`"
      >
        <v-row>
          <v-col cols="12">
            <v-row justify="center" v-if="user && group == 'user'">
              <v-col
                cols="11"
                md="4"
                lg="3"
                class="ma-0 pa-0 ma-md-4"
                align-self="center"
              >
                <v-card
                  class="rounded-xl my-3"
                  :elevation="0"
                  color="rgba(0,0,0,0)"
                  :style="`color: ${template.colors.navigation_inline_text};`"
                  link
                  :to="
                    user.data.verband == template.var.verband &&
                    (user.data.rolle == 'Trainer' ||
                      user.data.rolle == 'Vorstand' ||
                      user.data.rolle == 'Zuschauerkontrolle')
                      ? '/'
                      : undefined
                  "
                >
                  <v-row class="px-2 mb-5">
                    <v-col
                      cols="2"
                      align-self="center"
                      v-if="
                        user.data.verband == template.var.verband &&
                        (user.data.rolle == 'Trainer' ||
                          user.data.rolle == 'Vorstand' ||
                          user.data.rolle == 'Zuschauerkontrolle')
                      "
                    >
                      <v-icon
                        large
                        :style="`color: ${template.colors.navigation_inline_text};`"
                      >
                        mdi-chevron-left
                      </v-icon>
                    </v-col>
                    <v-col cols="2" align-self="center">
                      <v-avatar
                        :tile="user.data.verein ? true : false"
                        class="my-auto"
                      >
                        <v-img
                          :src="
                            user.data.verein
                              ? user.data.verein.logo
                              : user.photoUrl
                          "
                        ></v-img>
                      </v-avatar>
                    </v-col>
                    <v-col cols="8" align-self="center">
                      <span class="font-weight-bold">
                        {{ user.displayName }}
                      </span>
                      <br />
                      <small>
                        <b>{{ user.data.rolle }}</b>
                        {{
                          user.data.verein ? ' | ' + user.data.verein.name : ''
                        }}
                      </small>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
              <v-col
                cols="11"
                md="4"
                lg="3"
                class="ma-0 pa-0 ma-md-4"
                align-self="center"
              >
                <cardbutton
                  :title="'Account verwalten'"
                  :icon="'mdi-ticket-account'"
                  :textcolor="template.colors.navigation_blocks_text"
                  :bgcolor="template.colors.navigation_blocks"
                  upper
                  to="/user"
                  @click="menu = false"
                />
              </v-col>
              <v-col
                cols="11"
                md="4"
                lg="3"
                class="ma-0 pa-0 ma-md-4"
                align-self="center"
              >
                <cardbutton
                  :title="'QR-CODE SCAN'"
                  :icon="'mdi-qrcode-scan'"
                  :textcolor="template.colors.navigation_blocks_text"
                  :bgcolor="template.colors.navigation_blocks"
                  to="/user/scan"
                  @click="menu = false"
                />
              </v-col>
              <v-col
                cols="11"
                md="4"
                lg="3"
                class="ma-0 pa-0 ma-md-4"
                align-self="center"
              >
                <cardbutton
                  :title="'MEINE AKTIVEN CheckIns'"
                  :icon="'mdi-ticket-account'"
                  :textcolor="template.colors.navigation_blocks_text"
                  :bgcolor="template.colors.navigation_blocks"
                  to="/user/tickets"
                  @click="menu = false"
                />
              </v-col>
              <v-col
                cols="11"
                md="4"
                lg="3"
                class="ma-0 pa-0 ma-md-4"
                align-self="center"
              >
                <cardbutton
                  :title="'Meine Besuche'"
                  :icon="'mdi-history'"
                  :textcolor="template.colors.navigation_blocks_text"
                  :bgcolor="template.colors.navigation_blocks"
                  upper
                  to="/user/besuche"
                  @click="menu = false"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row justify="end" class="mt-auto">
          <v-col cols="4" md="3" lg="2">
            <v-card
              :elevation="0"
              color="rgba(0,0,0,0)"
              :style="`color: ${template.colors.navigation_inline_text};`"
              link
              @click="
                logout()
                menu = false
              "
            >
              <v-row class="px-2 mb-5">
                <v-col cols="12" class="text-center mb-0 pb-0">
                  <v-icon :color="template.colors.navigation_inline_text" large>
                    mdi-logout
                  </v-icon>
                </v-col>
                <v-col cols="12" class="text-center mt-0">
                  <span class="text-center">
                    LOGOUT
                  </span>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="11" class="text-center">
            <v-btn
              outlined
              large
              :block="$vuetify.breakpoint.smAndDown"
              :color="template.colors.navigation_inline_text"
              to="/releases"
              class="my-4 mx-md-4"
            >
              Release Notes
            </v-btn>
            <v-btn
              outlined
              large
              :block="$vuetify.breakpoint.smAndDown"
              :color="template.colors.navigation_inline_text"
              :to="
                !template.datenschutz
                  ? '/datenschutz'
                  : template.datenschutz.link
                  ? undefined
                  : '/datenschutz'
              "
              :href="
                template.datenschutz
                  ? template.datenschutz.link
                    ? template.datenschutz.link
                    : undefined
                  : undefined
              "
              class="my-4 mx-md-4"
            >
              Datenschutz
            </v-btn>
            <v-btn
              outlined
              large
              :block="$vuetify.breakpoint.smAndDown"
              :color="template.colors.navigation_inline_text"
              to="/impressum"
              class="my-4 mx-md-4"
            >
              Impressum
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-slide-x-transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import logout from '../../auth/auth'
import router from '../../routes/index'
import cardbutton from '../../elements/cards/card-button'

export default {
  name: 'Menubar',
  data() {
    return {
      menu: false,
      group: 'user',
    }
  },
  components: {
    cardbutton,
  },
  computed: {
    ...mapGetters({
      user: 'user',
      publicdata: 'publicdata',
      template: 'template',
    }),
  },
  methods: {
    logout() {
      logout.logout()
      setTimeout(() => {
        if (this.$route.params.verein) {
          router.push('/' + this.$route.params.verein)
        } else if (this.publicdata) {
          if (this.publicdata.verein) {
            router.push('/' + this.publicdata.verein.alias)
          } else {
            router.push('/')
          }
        } else {
          router.push('/')
        }
      }, 500)
    },
  },
}
</script>
