<template>
  <div>
    <v-container fluid style="padding-bottom: 10vh;">
      <div v-if="!user.data">
        <Menubar />
      </div>
      <div v-if="user.data">
        <UserMenu
          v-if="user.data.rolle == 'Trainer' || user.data.rolle == 'Vorstand'"
        />
        <ZuschauerMenu v-if="user.data.rolle == 'Zuschauer'" />
        <SchiriMenu v-if="user.data.rolle == 'Schiedsrichter'" />
      </div>
      <v-main>
        <v-row justify="center">
          <v-col cols="12" md="8" lg="6">
            <v-row justify="center">
              <v-col cols="12">
                <h1>IMPRESSUM</h1>
                <h2 class="mt-4">Angaben gemäß § 5 TMG</h2>

                <div v-if="template.impressum">
                  <span v-html="template.impressum.herausgeber"></span>
                  <h2 v-if="template.impressum.vertreten_durch" class="mt-4">
                    Vertreten durch
                  </h2>
                  <span v-html="template.impressum.vertreten_durch"></span>
                  <h2 v-if="template.impressum.registereintrag" class="mt-4">
                    Registereintrag
                  </h2>
                  <span v-html="template.impressum.registereintrag"></span>
                  <h2 v-if="template.impressum.urheberrecht" class="mt-4">
                    Urheberrecht
                  </h2>
                  <span v-html="template.impressum.urheberrecht"></span>
                  <h2 v-if="template.impressum.haftung" class="mt-4">
                    Haftungshinweis
                  </h2>
                  <span v-html="template.impressum.haftung"></span>
                </div>
                <div v-if="!template.impressum">
                  FLVW Marketing GmbH
                  <br />
                  Jahnstraße 76-78
                  <br />
                  59174 Kamen
                  <br />
                  Handelsregisternummer: HRB 6397
                  <br />
                  Registergericht: Amtsgericht Hamm
                  <br />
                  Geschäftsführer: Maurice Hampel
                  <br />
                  <h2 class="mt-4">Kontakt</h2>
                  Telefon: (02307) 92 492 0
                  <br />
                  E-Mail: support@flvw-marketing.de
                  <br />
                  <br />
                  Umsatzsteuer-ID
                  <br />
                  Umsatzsteuer-Identifikationsnummer gemäß §27 a
                  Umsatzsteuergesetz:
                  <br />
                  DE263880997
                  <h2 class="mt-4">Haftung für Inhalte</h2>
                  <span>
                    Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene
                    Inhalte auf diesen Seiten nach den allgemeinen Gesetzen
                    verantwortlich. Nach §§ 8 bis 10 TMG sind wir als
                    Diensteanbieter jedoch nicht verpflichtet, übermittelte oder
                    gespeicherte fremde Informationen zu überwachen oder nach
                    Umständen zu forschen, die auf eine rechtswidrige Tätigkeit
                    hinweisen. Verpflichtungen zur Entfernung oder Sperrung der
                    Nutzung von Informationen nach den allgemeinen Gesetzen
                    bleiben hiervon unberührt. Eine diesbezügliche Haftung ist
                    jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten
                    Rechtsverletzung möglich. Bei Bekanntwerden von
                    entsprechenden Rechtsverletzungen werden wir diese Inhalte
                    umgehend entfernen.
                  </span>
                  <h2 class="mt-4">Urheberrecht</h2>
                  <span>
                    Die durch die Seitenbetreiber erstellten Inhalte und Werke
                    auf diesen Seiten unterliegen dem deutschen Urheberrecht.
                    Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art
                    der Verwertung außerhalb der Grenzen des Urheberrechtes
                    bedürfen der schriftlichen Zustimmung des jeweiligen Autors
                    bzw. Erstellers. Downloads und Kopien dieser Web-App sind
                    nur für den privaten, nicht kommerziellen Gebrauch
                    gestattet. Soweit die Inhalte auf dieser Seite nicht vom
                    Betreiber erstellt wurden, werden die Urheberrechte Dritter
                    beachtet. Insbesondere werden Inhalte Dritter als solche
                    gekennzeichnet. Sollten Sie trotzdem auf eine
                    Urheberrechtsverletzung aufmerksam werden, bitten wir um
                    einen entsprechenden Hinweis. Bei Bekanntwerden von
                    Rechtsverletzungen werden wir derartige Inhalte umgehend
                    entfernen.
                  </span>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-main>
    </v-container>
  </div>
</template>

<script>
import Menubar from '../components/public/nav'
import UserMenu from '../components/nav/nav'
import ZuschauerMenu from '../components/zuschauer/nav'
import SchiriMenu from '../components/schiedsrichter/nav'
import { mapGetters } from 'vuex'

export default {
  name: 'Impressum',
  components: {
    Menubar,
    UserMenu,
    ZuschauerMenu,
    SchiriMenu,
  },
  computed: {
    ...mapGetters({
      user: 'user',
      template: 'template',
      timer: 'timer',
    }),
  },
}
</script>
